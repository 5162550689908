import image from "../../images/pamovations.png"
import "../../components/css/main.scss"
import { useEffect, useRef, useState } from "react";
const className = {
    box: "box p-3 m-3",
    boxHeader: "box-header text-center font-bold text-[2rem]",
    boxContainer: "box-container grid grid-cols-1 w-full md:flex md:justify-center md:flex-wrap gap-2 text-[0.9rem]",
    boxItem: " w-12/12 md:w-2/12 hover:motion-reduce:animate-bounce",
    divider: "border-b border-slate p-2 my-2"
};

const sections = [
    {
        name: "Our Services"
    }
];

export const Dashboard = () => {
    const nav = useRef();
    const [navVisible, setNavVisible] = useState("");
    window.addEventListener('scroll', () => {
        if (nav?.current) {
            // console.log(window.scrollY + navVisible + nav?.current?.classList);
            let styleIn = 'menu-scrolled';
            if (window.scrollY > 100) {
                styleIn.split(" ").forEach((style) => {
                    nav?.current?.classList?.add(style);
                });
            } else {
                styleIn.split(" ").forEach((style) => {
                    nav?.current?.classList?.remove(style);
                });
            }
        }
    });

    const text = "I am Petro Jackson";
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        let index = 0;

        const timer = setInterval(() => {
            setDisplayedText((prev) => prev + text[index]);
            index++;

            if (index === text.length) {
                clearInterval(timer);
            }
        }, 500);

        return () => clearInterval(timer);
    }, [text]);
    return (
        <div id="Home" className="main-container">
            <nav ref={(ref) => nav.current = ref} className={"menu w-full p-5"}>
                <div className="logo mr-3">
                    <img width={100} height={100} src={image} alt="PAMOVATIONS" />
                </div>
                <ul className="flex text-white gap-2">
                    <li><a href="#Home">Home</a></li>
                    <li><a href="#Services">Services</a></li>
                    <li><a href="#Products">Products</a></li>
                    <li><a href="#Contacts">Contacts</a></li>
                    <li><a href="#About">About</a></li>
                </ul>
            </nav>

            <div className="page-header">
                <div className="bg-transparent w-full h-[500px] m-auto flex justify-center">
                    <div className="m-auto text-center font-bold text-[2rem]">
                        <div className="bg-slate-600 text-white">
                            PAMOVATIONS
                        </div>
                        <div className="bg-white text-slate-600 p-2 z-0">
                            {/* <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span> */}
                            <span>
                                Home Of Innovations
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bg-[#D46119] border-t border-slate-200 text-white shadow-lg p-5"></div> */}
            <div className="">
                <section id="Services" className={className.box}>
                    <div className={className.boxHeader + " box-header"}>
                        <h1>Our Services</h1>
                    </div>
                    <div className={className.boxContainer}>
                        <article className={className.boxItem + " item"}>
                            <h1>Web Development</h1>
                            <p>
                                Building, Designing, and Deployment of web software.
                            </p>
                        </article>
                        <article className={className.boxItem + " item"}>
                            <h1>Android Development</h1>
                            <p>
                                Building, Designing, and Deployment of android software.
                            </p>
                        </article>
                        <article className={className.boxItem + " item"}>
                            <h1>Desktop Development</h1>
                            <p>
                                Building, Designing, and Deployment of desktop software.
                            </p>
                        </article>
                    </div>
                </section>
                <section id="Products" className={className.box}>
                    <div className={className.boxHeader}>
                        <h1>Products</h1>
                    </div>
                    <div className={className.boxContainer}>
                        <article className={className.boxItem + " item flex flex-col"}>
                            <h1>IMS</h1>
                            <p className="grow">
                                An Inventory Management System, which helps you to manage and control you items,sales,orders and income.
                            </p>
                            {/* <a 
                        href="#" 
                        target="_blank"
                        className={"bg-sky-900 hover:bg-sky-800 border border-slate-900 shadow-md text-white rounded-full p-2 mt-3 mx-5"}
                        >Try</a> */}
                        </article>
                        <article className={className.boxItem + " item flex flex-col"}>
                            <h1>eSHOP</h1>
                            <p className="grow">
                                electronic SHOP.
                            </p>

                            {/* <a 
                        href="#" 
                        target="_blank"
                        className={"bg-sky-900 hover:bg-sky-800 border border-slate-900 shadow-md text-white rounded-full p-2 mt-3 mx-5"}
                        >Try</a> */}
                        </article>
                        <article className={className.boxItem + " item flex flex-col"}>
                            <h1>HMS</h1>
                            <p className="grow">
                                Hotel Management System.
                            </p>

                            {/* <a 
                        href="#" 
                        target="_blank"
                        className={"bg-sky-900 hover:bg-sky-800 border border-slate-900 shadow-md text-white rounded-full p-2 mt-3 mx-5"}
                        >Try</a> */}
                        </article>
                        <article className={className.boxItem + " item flex flex-col"}>
                            <h1>Invoice System</h1>
                            <p className="grow">
                                Generating and managing your invoices in easier way.
                            </p>

                            {/* <a 
                        href="#" 
                        target="_blank"
                        className={"bg-sky-900 hover:bg-sky-800 border border-slate-900 shadow-md text-white rounded-full p-2 mt-3 mx-5"}
                        >Try</a> */}
                        </article>
                        <article className={className.boxItem + " item flex flex-col"}>
                            <h1>School Management System</h1>
                            <p className="grow">
                                Managing school records,student records, results, examination and accounting.
                            </p>

                            {/* <a 
                        href="#" 
                        target="_blank"
                        className={"bg-sky-900 hover:bg-sky-800 border border-slate-900 shadow-md text-white rounded-full p-2 mt-3 mx-5"}
                        >Try</a> */}
                        </article>
                    </div>
                </section>
                <section id="Contacts" className={className.box}>
                    <div className={className.boxHeader}>
                        <h1>Contacts</h1>


                        <p className="text-slate-600 text-[2rem]">
                            <i className="fa-solid fa-phone">+255 621 555 169</i>
                        </p>
                        {/* <div className="flex gap-2">
                            <a href="https://www.facebook.com/pamovations" target="_blank" className="text-slate-600 hover:text-slate-900">
                                <i className="fa-brands fa-facebook">facebook</i>
                            </a>
                            <a href="https://twitter.com/pamovations" target="_blank" className="text-slate-600 hover:text-slate-900">
                                <i className="fa-brands fa-twitter">X</i>
                            </a>
                            <a href="https://www.instagram.com/pamovations/" target="_blank" className="text-slate-600 hover:text-slate-900">
                                <i className="fa-brands fa-instagram">Instagram</i>
                            </a>
                        </div> */}
                    </div>
                    <article>

                    </article>
                </section>
                <section className={className.box}>
                    <div className={className.boxHeader}>
                        <h1>Team</h1>
                    </div>
                    <div>
                        <article>

                        </article>
                    </div>
                </section>
            </div>
        </div>
    );
}